// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/login/login-crm-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".crm-login[data-v-8072a5f6]{position:relative}.login-page[data-v-8072a5f6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;position:fixed;z-index:0;width:100vw;overflow:hidden;top:0;bottom:0;left:0}.crm-login .center-box[data-v-8072a5f6]{position:absolute;top:50%;left:85%;transform:translate(-50%,-50%);z-index:100;text-align:center}.center-box-logo>img[data-v-8072a5f6]{width:272px;height:83px}.center-box-logo[data-v-8072a5f6]{position:absolute;top:12.5%;left:18%;transform:translate(-50%,-50%);z-index:100;text-align:center}.login-warp[data-v-8072a5f6]{box-sizing:border-box;position:absolute;right:220px;top:50%;margin-top:-240px;width:480px;height:480px;background:#fff;box-shadow:0 0 10px 0 rgba(7,93,197,.2);border-radius:6px;padding:0 40px}.login-title[data-v-8072a5f6]{font-size:31px;font-weight:700;color:#1378f2;padding:30px 0;border-bottom:1px solid #d9d9d9;text-align:center}.login-input[data-v-8072a5f6],.mt-20[data-v-8072a5f6]{margin-top:20px}.login-input[data-v-8072a5f6]  .el-input__inner{height:54px;font-size:16px;color:#1a1a1a;padding-left:70px}.login-input[data-v-8072a5f6]  .el-input__prefix{padding:0 25px 0 18px}.login-input[data-v-8072a5f6]  .el-input__suffix{padding-right:18px}.login-input[data-v-8072a5f6]  .el-input__icon{line-height:54px;font-size:20px;color:#b3b3b3}.login-btn__submit[data-v-8072a5f6]{width:100%;height:54px;line-height:54px;font-size:18px;background:#2878ff;color:#fff;letter-spacing:2px;border-radius:2px;cursor:pointer;margin-top:60px;text-align:center}", ""]);
// Exports
module.exports = exports;
