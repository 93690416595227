
import { ajax, httpPost } from '@/api';
import { useStore } from '@/store';
import { errorMessage } from '@/utils/publicMethods';
import { AxiosRequestConfig } from 'axios';
import { defineComponent, inject, reactive } from 'vue';
import { useRouter } from 'vue-router';
import useLoading, { LoadingType } from '@/hooks/useLoading';
import { ErpUseInfo } from '@/types/type';
interface LoginForm {
  loginName: string | number;
  loginPwd: string | number;
}
export default defineComponent({
  name: 'Login',
  setup() {
    // 登录数据
    const store = useStore();
    const router = useRouter();
    const loginForm = reactive({
      loginName: '',
      loginPwd: '',
    });
    // 这里用注入的得到方法，共用同一个实例
    const { closeLoading } = inject<LoadingType>('loading', useLoading());
    // 只要进到登录页一定要清除下登录
    closeLoading();
    const validLogin = (formData: LoginForm): boolean => {
      const { loginName, loginPwd } = formData;
      if (loginName === '') {
        errorMessage('请输入用户名');
        return false;
      }
      if (loginPwd === '') {
        errorMessage('请输入密码');
        return false;
      }
      return true;
    };
    const getMenuList = (): void => {
      httpPost('/uac/user/loginAfter/1').then((res) => {
        if (res.code === 200) {
          store.dispatch('setDepartment', res.result);
          store.dispatch('setUserInfo', res.result as ErpUseInfo).then((status: boolean) => {
            if (status) {
              // 获取用户登录信息
              store.dispatch('changeMenu', 'CRM').then(() => {
                router.push({ path: '/clue/clueList' });
              });
            }
          });
        }
      });
    };
    const login = (): void => {
      if (validLogin(loginForm)) {
        const params: AxiosRequestConfig = {
          url: '/uac/auth/form',
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          auth: {
            username: 'mallee-saas-uac',
            password: 'maliSoaClientSecret',
          },
          params: {
            username: loginForm.loginName,
            password: loginForm.loginPwd,
          },
        };
        ajax(params).then((res) => {
          if (res.code === 200) {
            store.dispatch('setTokenInfo', res.result).then(() => {
              getMenuList(); // 获取菜单列表信息
            });
          } else {
            errorMessage(res.message);
          }
        });
      }
    };
    // 清除缓存数据
    store.dispatch('clearUserInfo');
    //清除缓存数据
    store.dispatch('clearCache');
    return { loginForm, login };
  },
});
